import { ResourcesConfig } from "aws-amplify";

export const configuration: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID_QA!,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID_QA!,
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_COGNITO_DOMAIN_QA!,
          scopes: ["email", "profile", "openid"],
          redirectSignIn: [process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN_QA!],
          redirectSignOut: [process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT_QA!],
          responseType: "code",
        },
      },
    },
  },
};
